<template>
    <div>
        <template v-if="$slots.openButton">
            <div @click="open">
                <slot name="openButton"></slot>
            </div>
        </template>
        <template v-else>
            <div :class="[
                !buttonMode &&
                    !hide &&
                    'user_draw_input ant-input flex items-center relative',
                inputClass,
                size,
                disabled && 'ant-input-disabled',
            ]">
                <template v-if="buttonMode && !hide">
                    <a-button
                        :type="buttonType"
                        :size="buttonSize"
                        @click="open()"
                        :block="buttonBlock"
                        :icon="buttonIcon"
                        :disabled="buttonDisabled"
                        :loading="buttonLoading">
                        {{ buttonText }}
                    </a-button>
                </template>
                <template v-else-if="!hide">
                    <template v-if="checkMultiple">
                        <template v-if="multiple">
                            <template v-if="value && value.length">
                                <Profiler :user="value[0]" class="mr-2">
                                    <a-tag color="blue" class="tag_block" @click="open()">
                                        <div class="flex items-center">
                                            <div class="mr-1">
                                                <a-avatar
                                                    :key="value[0].id"
                                                    :size="15"
                                                    icon="user"
                                                    :src="
                                                        value[0].avatar && value[0].avatar.path
                                                            ? value[0].avatar.path
                                                            : ''
                                                    "/>
                                            </div>
                                            {{ checkNameList(value[0]) }}
                                        </div>
                                    </a-tag>
                                </Profiler>
                                <a-popover class="mr-2">
                                    <template slot="content">
                                        <div class="user_pop_scroll">
                                            <template v-for="(item, index) in value">
                                                <template v-if="index !== 0">
                                                    <div
                                                        :key="`o_${index}`"
                                                        class="flex items-center user_popup_item">
                                                        <div class="mr-1">
                                                            <a-avatar
                                                                v-if="item.avatar"
                                                                :size="15"
                                                                :src="item.avatar.path"/>
                                                            <a-avatar v-else :size="15" icon="user" />
                                                        </div>
                                                        {{ checkNameList(item) }}
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </template>
                                    <a-tag
                                        v-if="value.length > 1"
                                        color="blue"
                                        class="tag_block"
                                        @click="open()">
                                        + {{ value.length - 1 }}
                                    </a-tag>
                                </a-popover>
                                <a-button
                                    @click="clearList()"
                                    type="link"
                                    icon="close-circle"
                                    class="px-0 text-current remove_users"/>
                            </template>
                        </template>
                        <Profiler v-else :user="value" class="mr-2">
                            <a-tag color="blue" class="tag_block" @click="open()">
                                <div class="flex items-center">
                                    <div class="mr-1">
                                        <a-avatar
                                            :key="value.id"
                                            :size="15"
                                            icon="user"
                                            :src="
                                                value.avatar && value.avatar.path ? value.avatar.path : ''
                                            "/>
                                    </div>
                                    {{ checkName }}
                                </div>
                            </a-tag>
                        </Profiler>
                    </template>
                    <a-button @click="open()" type="link" class="px-0">
                        {{ $t("task.change") }}
                    </a-button>
                </template>
            </div>
        </template>

        <a-drawer
            class="user_select_drawer"
            :class="multiple && 'multiple_select'"
            :width="drawerWidth"
            destroyOnClose
            :title="driwerTitle"
            :zIndex="1200"
            :visible="visible"
            @close="closeDrawer">
            <div class="drawer_body us_dr_bd">
                <div class="search_wrap">
                    <PageFilter
                        :model="model"
                        :key="pageName"
                        onlySearch
                        size="large"
                        :page_name="pageName"/>
                </div>

                <template v-if="oldSelected && oldSelectedVisible">
                    <div class="mb-6">
                        <h2 class="mb-2.5 text-base">
                            {{ $t("old_selected") }}
                        </h2>
                        <OldSelected
                            ref="oldSelector"
                            :showLabel="false"
                            :multiple="multiple"
                            :checkSelected="checkSelected"
                            :itemSelect="selectUser"
                            :getPopupContainer="getPopupContainer"/>
                    </div>
                </template>

                <!-- Пока функционала фильтров нет. Поэтому отключаем рендеринг -->
                <template v-if="true"> 
                    <div class="mb-6">
                        <h2 class="mb-2.5 text-base">
                            {{ $t('users_filter') }}
                        </h2>
                        <div class="mb-2.5">
                            <InlineFilters />
                        </div>
                    </div>
                </template>

                <div class="user_tree">
                    <UserTree
                        ref="userTreeRef"

                        :metadata="metadata"
                        :changeMetadata="changeMetadata"

                        :multiple="multiple"
                        :selected="value"
                        :selectSingleUser="selectSingleUser"
                        :selectUsersList="selectUsersList"
                        :model="model"
                        :id="id"/>
                </div>
            </div>
            <div class="drawer_footer flex items-center">
                <template v-if="submitHandler">
                    <a-button type="primary" block class="px-8" @click="submit">
                        {{ submitButtonText || $t('select') }}
                    </a-button>
                </template>
                <a-button type="ui" ghost block class="px-8" @click="visible = false">
                    {{ $t("task.close") }}
                </a-button>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import eventBus from "@/utils/eventBus";
import OldSelected from "./OldSelected.vue";
import UserTree from "./UserTree.vue";
import PageFilter from "@/components/PageFilter";
import InlineFilters from './InlineFilters'
import { setData, getById, updateById, deleteDb } from "./utils/userDb";

export default {
    components: {
        PageFilter,
        OldSelected,
        UserTree,
        InlineFilters
    },
    props: {
        metadata: {
            type: Object,
            default: () => {}
        },
        changeMetadata: {
            type: Function,
            default: () => {}
        },
        submitHandler: {
            type: Function,
            default: null
        },
        submitButtonText: {
            type: String,
            default: null,
        },
        value: {
            // v-model значение, если multiple false то передаем Object, если true то Array
            type: [Object, Array, String],
        },
        id: {
            type: [String, Number],
            required: true,
        },
        taskId: {
            type: [String, Number],
            default: () => null,
        },
        multiple: {
            // Включить множественный выбор юзеров
            type: Boolean,
            default: false,
        },
        title: {
            // Заголовок Drawer окна
            type: String,
            default: "",
        },
        buttonMode: {
            // Включить вывод кнопки вместо поля, ниже все пропсы связанные с настройкой кнопки
            type: Boolean,
            default: false,
        },
        buttonType: {
            // Тип кнопки
            type: String,
            default: "primary",
        },
        buttonText: {
            // Текст кнопки ключем перевода
            type: String,
            default: "",
        },
        buttonBlock: {
            // Сделать кнопку по всю ширину
            type: Boolean,
            default: false,
        },
        buttonIcon: {
            // Иконка кнопки
            type: String,
            default: "",
        },
        buttonSize: {
            // Размер кнопки
            type: String,
            default: "default",
        },
        buttonDisabled: {
            // Отключить кнопку
            type: Boolean,
            default: false,
        },
        buttonLoading: {
            // Загрущик в кнопке
            type: Boolean,
            default: false,
        },
        hide: {
            // Скрыть визуально кнопку и поле
            type: Boolean,
            default: false,
        },
        excludeUsers: {
            // Массив с id юзеров, которых нужно исключить из списка
            type: Array,
            default: () => [],
        },
        showRadio: {
            type: Boolean,
            default: true,
        },
        inputClass: {
            type: String,
            default: "",
        },
        inputSize: {
            type: String,
            default: "default",
        },
        // filters: {
        //     type: Object,
        //     default: ()=> {}
        // },
        disabled: {
            type: Boolean,
            default: false,
        },
        oldSelected: {
            type: Boolean,
            default: true,
        },
        model: {
            type: String,
            default: "users.ProfileModel",
        },
        pageName: {
            type: String,
            default: 'user_select',
        },
    },
    data() {
        return {
            /** Переменная для хранения выбранных элементов. Инициализирована ранее выбранными элементами  */
            selectedList: this.value || [],

            visible: false,
            oldSelectedUsers: [],

            dbId: "user",
            databaseName: "old_select",
            oldSelectedVisible: false,
        };
    },
    computed: {
        user() {
            return this.$store.state.user.user;
        },
        driwerTitle() {
            return this.title || this.$t("task.select_user");
        },
        drawerWidth() {
            const baseWidth = 720;
            const offset = 40;
            return this.windowWidth > baseWidth + offset
                ? baseWidth
                : this.windowWidth;
        },
        size() {
            return this.inputSize === "large" ? "ant-input-lg" : "default";
        },
        windowWidth() {
            return this.$store.state.windowWidth;
        },
        checkMultiple() {
            if (this.multiple) {
                return this.value.length;
            }
            return this.value;
        },
        checkName() {
            if (this.value.full_name) {
                return this.value.full_name;
            }
            return this.value.last_name + " " + this.value.first_name;
        },
        isMobile() {
            return this.$store.state.isMobile;
        },
    },
    methods: {
        submit() {
            if (this.submitHandler) {
                this.submitHandler();
            }
            this.closeDrawer();
        },
        getUniqueUsers(arr1, arr2) {
            const combined = [...arr1, ...arr2];

            const uniqueUsers = combined.filter(
                (user, index, self) => index === self.findIndex((u) => u.id === user.id)
            );

            return uniqueUsers;
        },

        closeDrawer() {
            this.visible = false;
        },
        getPopupContainerFilter() {
            return this.$refs.drawer_header;
        },
        getPopupContainer() {
            return document.querySelector(".us_dr_bd");
        },
        clearList() {
            this.$emit("input", []);
            this.changeMetadata({ key: this.metadata.key, value: [] });
            console.log(this.metadata)
        },
        clearStateTree() {
            const payload = {
                page_name: "xxx.xxx",
                key: "users.ProfileModel",
                others: {
                    state_tree: [],
                },
            };
            this.$http.post("app_info/chosen_filters/", payload).catch((error) => {
                this.$message.error("Не удалось сохранить изменения");
                console.error(error);
            });
        },
        checkNameList(user) {
            if (user.full_name) return user.full_name;
            else return user.last_name + " " + user.first_name;
        },
        checkSelected(user) {
            if (this.multiple) {
                const index = this.value.findIndex((u) => u.id === user.id);
                return index !== -1;
            }
            return user.id === this.value.id;
        },
        open() {
            if (!this.disabled) this.visible = true;
        },
        selectUsersList(users) {
            if (this.multiple) {
                const uniqueUsers = this.getUniqueUsers(users, this.oldSelectedUsers);
                this.value.splice(0);
                this.value.push(...uniqueUsers);

                if (this.oldSelected) {
                    uniqueUsers.forEach(user => {
                        this.$refs.oldSelector.saveSelect(user);
                    })
                }

            }
        },
        selectSingleUser(user) {
            this.$emit("input", user);
            this.visible = false;
        },
        removeUserFromOldSelected(userId) {
            const index = this.oldSelectedUsers.findIndex(
                (user) => user.id === userId
            );
            this.oldSelectedUsers.splice(index, 1);
        },

        selectUser(user, oldSelected = null) {

            if (this.multiple) {
                let users = this.value;
                if (oldSelected) {
                    const index = this.value.findIndex((u) => u.id === user.id);
                    if (index !== -1) {
                        this.oldSelectedUsers.splice(index, 1);
            this.$refs?.userTreeRef?.checkHandler({
                checked: false,
                item: user,
            });
                    } else {
                        this.oldSelectedUsers.push(user);
                    }
                } else {
                    const index = users.findIndex((u) => u.id === user.id);
                    if (index !== -1) {
                        users.splice(index, 1);
            this.$refs?.userTreeRef?.checkHandler({
                checked: false,
                item: user,
            });
                    } else {
                        users.push(user);
            this.$refs?.userTreeRef?.checkHandler({
                checked: true,
                item: user,
            });
                    }
                }
                this.$emit("input", this.getUniqueUsers(users, this.oldSelectedUsers));
            } else {
                this.selectSingleUser(user);
            }


            if (this.oldSelected) this.$refs.oldSelector.saveSelect(user);
        },

        async getSelectedUsers() {
            try {
                const data = await getById({
                    id: `${this.dbId}_${this.user.id}`,
                    databaseName: this.databaseName,
                });
                // console.log('oldselected', `${this.dbId}_${this.user.id}`, this.databaseName,);
                // TODO
                // this.oldSelectedVisible = true;
                if (data?.value?.length) {
                    this.oldSelectedVisible = true;
                }
            } catch (e) {
                console.log(e);
            }
        },
    },
    created() {
        this.getSelectedUsers();
    },
    mounted() {
        eventBus.$on(`drawer_select_save_state_${this.id}`, () => {

        })

        eventBus.$on("open_user_task_drawer", (id) => {
            if (id === this.id) this.open();
        });
        eventBus.$on(`update_filter_${this.model}`, () => {});
    },
    beforeDestroy() {
        eventBus.$off(`drawer_select_save_state_${this.id}`);
        eventBus.$off("open_user_task_drawer");
        eventBus.$off(`update_filter_${this.model}`);
    },
};
</script>

<style lang="scss" scoped>
.drawer_body {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: calc(100% - 40px);
}

.search_wrap {
  margin-bottom: 25px;
  border: 1px solid #e9ecf5;
}


.user_tree {
  min-height: 0;

  padding: 25px;
  padding-bottom: 30px;
  border-radius: 16px;
  border: 1px solid #e9ecf5;
  overflow: scroll;
}

.user_popup_item {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.user_pop_scroll {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}

.user_draw_input {
  .remove_users {
    right: 0;
    top: 50%;
    position: absolute;
    margin-top: -16px;
  }
}

.user_select_drawer {
  &:not(.multiple_select) {
    .drawer_body {
      height: calc(100% - 40px);
    }
  }

  &.multiple_select {
    .drawer_body {
      height: calc(100% - 40px);
    }
  }

  &::v-deep {
    .ant-drawer-content,
    .ant-drawer-wrapper-body {
      overflow: initial;
    }

    .filter_pop_wrapper {
      min-width: 100%;
      max-width: 100%;

      .filter_input {
        border: 0px;
      }
    }

    .ant-drawer-body {
      padding: 0px;
      height: calc(100% - 40px);
    }

    .drawer_header {
      border-bottom: 1px solid var(--borderColor);
    }

    .drawer_footer {
      border-top: 1px solid var(--borderColor);
      height: 40px;
      background: var(--bgColor);
      padding: 0 15px;
      align-items: center;
    }

    .drawer_body {
      .drawer_scroll {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .item {
          &:not(:last-child) {
            border-bottom: 1px solid var(--borderColor);
          }

          &:hover {
            background: var(--hoverBg);
          }

          .name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
</style>
